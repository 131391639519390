import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

let baseQueryInstance;
let REACT_APP_API_BASE_URL;

export const httpOptions = {
    headers: new Headers({
        // 'Access-Control-Allow-Origin': '*', // Required for CORS support to work
    }),
    credentials: 'include',
    withCredentials: true,
};

export function createBaseQuery() {
    if (!baseQueryInstance) {
        baseQueryInstance = fetchBaseQuery({
            baseUrl: getBaseUrl(),
            responseHandler: 'content-type',
            ...httpOptions
        });
        return baseQueryInstance;
    }
    return baseQueryInstance;
}

export function setBaseUrl(newBaseUrl) {
    if (baseQueryInstance) {
        console.error('BaseQueryInstance has all ready been initialized. BaseUrl can not be modified anymore!');
        return;
    }
    REACT_APP_API_BASE_URL = newBaseUrl;
    baseQueryInstance = createBaseQuery();
}

export function getBaseUrl() {
    return (REACT_APP_API_BASE_URL || window?._env_?.REACT_APP_API_BASE_URL || window?.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL) + "/api"; // Default base URL
}
