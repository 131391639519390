import ReactDOM from 'react-dom/client';
import { lazy, Suspense } from 'react';
import { setBaseUrl } from './services/apiConfig';

const App = lazy(() => import('./App.js'));

// Function to fetch configuration
async function fetchConfig() {
    return new Promise((resolve, reject) => {

        const script = document.createElement('script');
        script.src = '/env-config.js';

        // Script load success callback
        script.onload = () => {
            if (window._env_) {
                // Ensure proper escaping for security
                window._env_ = JSON.parse(JSON.stringify(window._env_).replace(/</g, '\\u003c'));
                setBaseUrl(window._env_.REACT_APP_API_BASE_URL);
                resolve(window._env_);
            } else {
                window._env_ = {};
                reject(new Error('Config could not be loaded'));
            }
        };

        // Script load error callback
        script.onerror = () => {
            reject(new Error('Error loading the config script'));
        };

        script.async = false; // Ensure script is loaded synchronously
        document.head.appendChild(script);
    });
}

// Function to start the app
async function startApp() {
    try {
        // Fetch configuration and wait for it to complete
        await fetchConfig();

        // Render the app
        ReactDOM.createRoot(document.getElementById('app')).render(
            <Suspense fallback={<div></div>}>
                <App />
            </Suspense>
        );
    } catch (error) {
        console.error('Failed to load config', error);
    }
}

// Start the app
startApp();
